@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #813333;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Webkit browsers */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.custom-gridlines .p-datatable-tbody > tr > td {
  border-bottom: 1px solid #dee2e6;
  color: #000000; /* color for horizontal lines */
}

.custom-gridlines .p-datatable-thead > tr > th,
.custom-gridlines .p-datatable-tbody > tr > td {
  border-right: 1px solid #dee2e6; /* color for vertical lines */
}
.custom-gridlines .p-datatable-tbody > tr:nth-child(even) > td {
  background-color: #f0f0f0; /* Custom color for striped rows */
}

.custom-gridlines .p-datatable-tbody > tr:last-child > td {
  border-bottom: none; /* removes bottom border from last row */
}

.custom-gridlines .p-datatable-thead > tr > th:last-child,
.custom-gridlines .p-datatable-tbody > tr > td:last-child {
  border-right: none; /* removes right border from last column */
}
