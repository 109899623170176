 @import "./variables";

.card-title {
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }
  
  .bg-lightgray {
    background-color: #f5f5f5;
  }
  
  .table-bordered {
    border: 1px solid #dfdfdf !important;
  }
  
  .table th{
    background-color: $primary !important;
    color: white;
  }
  
  .table-header {
    background-color: $primary !important;
    padding: 8px !important;
    color: white;
    font-weight: bold;
  }
  
  td, th {
    // background-color: white !important;
    padding: 8px !important;
  }
  
  .portfolio-grid{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    border: 1px solid #dfdfdf;;
  
    .portfolio-item {
      font-weight: bold;
      padding: 10px;
      border-top: 1px solid #dfdfdf;;
      border-left: 1px solid #dfdfdf;;
  
      span{
        font-weight: normal;
      }
  
      &:nth-child(-n+2){
        border-top: 1px solid #dfdfdf;;
      }
      &:nth-child(odd){
        border-left: 1px solid #dfdfdf;;
      }
      &:nth-child(-n+3){
        border-top: none;
      }
      &:first-child,
      &:nth-child(3n+1){
        border-left: none;
      }
    }
  }
  
  .disabled {
    opacity: 0.7 !important;
  }

  .bg-red-primary{
    background-color: #ffffff !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .bg-blue-primary{
    background-color: #ffffff !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .bg-green-primary{
    background-color: #ffffff !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  
.registration-form {
  border: 1px solid black;
  padding: 20px 0px !important;
}

.registration-form .form-row {
  display: flex;
  justify-content: space-between;
}

.registration-form .form-col {
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;
}

.registration-form .photo-frame {
  border: 1px solid black;
  height: 200px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  object-fit: contain;
  margin-right: 20px;
  background-color: transparent;
}

.registration-form h1 {
  color: black !important;
  font-size: 28px;
  font-weight: 700 !important;
  text-align: center;
  width: 100%;
}
.text-primary-color{
  color:  rgba(5, 104, 57, 1) ;
}
.text-secondary-color{
  color: rgba(229, 49, 37, 1) ;
}
.registration-form .section-header {
  background: linear-gradient(
    90deg,
    rgba(5, 104, 57, 1) 0%,
    rgba(5, 104, 57, 1) 50%,
    rgba(229, 49, 37, 1) 50%
  );
  width: 100%;
  padding: 8px;
  color: white !important;
  font-size: 18px;
  font-weight: 600 !important;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0px;
}

.registration-form .form-container {
  padding: 10px 20px;
  padding-bottom: 0px;
}

.registration-form .label {
  font-size: 14px;
  color: black;
  width: fit-content;
  white-space: nowrap;
  padding: 0 5px;
  margin: 0px;
}

.registration-form input {
  height: 30px;
  width: 100%;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  padding: 5px 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.registration-form .checkbox-input input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.registration-form hr {
  color: black;
  opacity: 1;
  background-color: black;
}

.registration-form .sub-header {
  font-size: 20px;
  color: black !important;
  font-weight: 600 !important;
  margin-bottom: 20px;
  margin-left: -10px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.document-thumbnail {
  height: 80px;
  width: 80px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.document-thumbnail img {
  height: 80%;
  width: 80%;
  object-fit: contain;
}

// temp
